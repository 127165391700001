import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CandidateEmployerListService } from 'src/app/shared/services/candidate-employer-list.service';
declare var Calendly: any;
@Component({
  selector: 'app-candidate-details',
  templateUrl: './candidate-details.component.html',
  styleUrls: ['./candidate-details.component.scss']
})
export class CandidateDetailsComponent implements OnInit {

  candidateId: any;
  candidateNo:any
  candidateInfo: any = {};
  // @Input() Id: any;
  
  constructor(
      private readonly activeRoute: ActivatedRoute,
      private readonly candidateListService: CandidateEmployerListService
  ) {}

  ngOnInit() {
      this.candidateId = this.activeRoute.snapshot.paramMap.get("id");
      this.getSpecificCandidateDetails()
  }


  openCalendly(): void {
    // Use Calendly's popup widget to open the scheduling interface
    Calendly.initPopupWidget({
        url: "https://calendly.com/accounts-magnusrecruitment/15min", // Replace with your scheduling link
    });
}

  getSpecificCandidateDetails() {
      this.candidateListService
          .getSpecificCandidateForPublicListing(this.candidateId)
          .subscribe((res: any) => {
            this.candidateInfo = res?.body
            console.log('candidateee%%%%', this.candidateInfo)
          });
  }


}
