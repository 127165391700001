<div class="forgot_password_component">
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Forgot Password</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>forgot-password</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-form-area ptb-100">
    <div class="container-fluid">
        <form [formGroup]="forgotPassword" (ngSubmit)="submit()" id="contactForm">
            <div class="row contact-wrap">
                <div class="col-md-10 col-lg-10 mt-5 forgot_password">
                    <h1>Forgot password</h1>
                    <p>Enter your email address below and we will send you a link to reset your password.</p>
                    <div class="form-group mt-5">
                        <label class="fw-bold h5 mb-4">Email address</label>
                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Email" formControlName="email">
                <!-- <app-validation-error [control]="forgotPassword.get('email')"></app-validation-error> -->
                    </div>
                </div>
                <div class="col-md-8 col-lg-8">
                    <div class="text-center">
                        <button type="submit" class="btn contact-btn">Submit</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</div>
