<div class="d-flex justify-content-center mx-2 my-3">
    <button class="btn btn-outline-success me-auto mt-5" data-bs-toggle="offcanvas"
    data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Fill Your Details</button>
    <!-- <button class="btn btn-primary" (click)="backward()"><i
        class="fa-solid fa-backward"></i></button>
    <span class="mt-1 mx-2"><p class="fs-5 writingstyle"> {{contractName}}</p></span>
    <button class="btn btn-primary" (click)="forward()"><i
        class="fa-solid fa-forward"></i></button> -->
  </div>
  
  <div class="container-fluid" #pdfViewerContainer>
    <ngx-extended-pdf-viewer
      #pdfViewer
      [src]="pdfSrc"
      [showTextEditor]="true"
      [showOpenFileButton]="false"
      [(sidebarVisible)]="sidebarVisible"
      >
    </ngx-extended-pdf-viewer>
  </div>

  
  <div class="offcanvas  offcanvas-end" tabindex="-1" id="offcanvasRight"
    aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header">
      <h5 id="offcanvasRightLabel"></h5>
      <button type="button" class="btn-close text-reset"
        data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <form [formGroup]="contractForm"  >
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">Full Name</label>
          <input type="text" class="form-control" formControlName="candidateName">
          <!-- <app-validation-error
          [control]="
              contractForm.get(
                  'candidateName'
              )
          "
      ></app-validation-error>      -->
        </div>

        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">Title</label>
          <input type="text" class="form-control" formControlName="title">    
        </div>


        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">Gender</label>
          <input type="text" class="form-control" formControlName="gender">    
        </div>


        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">SurName</label>
          <input type="text" class="form-control" formControlName="surName">    
        </div>


        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">Given Name</label>
          <input type="text" class="form-control" formControlName="givenName">    
        </div>


        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">Date Of Birth</label>
          <input type="date" class="form-control" formControlName="dOB">    
        </div>

        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">TFN</label>
          <input type="text" class="form-control" formControlName="tfn">    
        </div>


        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">ABN</label>
          <input type="text" class="form-control" formControlName="abn">    
        </div>


        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">Unit And Street</label>
          <input type="text" class="form-control" formControlName="unitAndStreet">    
        </div>

        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">Suburb</label>
          <input type="text" class="form-control" formControlName="suburb">    
        </div>

        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">State</label>
          <input type="text" class="form-control" formControlName="state">    
        </div>


        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">State</label>
          <input type="text" class="form-control" formControlName="state">    
        </div>

        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">PostCode</label>
          <input type="text" class="form-control" formControlName="postCode">    
        </div>


        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">Company Name</label>
          <input type="text" class="form-control" formControlName="companyName">    
        </div>

        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">Super Fund Name</label>
          <input type="text" class="form-control" formControlName="superFundName">    
        </div>

        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">Super Fund ABN</label>
          <input type="text" class="form-control" formControlName="superFundABN">    
        </div>

        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">Super Fund MemberShip</label>
          <input type="text" class="form-control" formControlName="superFundMemberShip">    
        </div>


        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">Commencement Date</label>
          <input type="date" class="form-control" formControlName="commencementDate">    
        </div>

        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">End Date</label>
          <input type="date" class="form-control" formControlName="endDate">    
        </div>

        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">Amount</label>
          <input type="text" class="form-control" formControlName="amount">    
        </div>

        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">Services Performed</label>
          <input type="text" class="form-control" formControlName="servicesPerformed">    
        </div>

        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">Trainer Assessor</label>
          <input type="text" class="form-control" formControlName="trainerAssessor">    
        </div>

        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">Service Order Date</label>
          <input type="date" class="form-control" formControlName="serviceOrderDate">    
        </div>

        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">Phone Number</label>
          <input type="text" class="form-control" formControlName="phoneNumber">
          <app-validation-error
          [control]="
              contractForm.get(
                  'phoneNumber'
              )
          "
      ></app-validation-error> 
        </div>

        <!-- <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">Position</label>
          <input type="text" class="form-control" formControlName="position">    
        </div> -->

        <!-- <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">Date</label>
          <input type="date" class="form-control" id="date" placeholder="Date" formControlName="date">
       </div> -->
      
      <div class="block border border-4 my-auto">
        <canvas
        #canvas
        (touchstart)="startDrawing($event)"
        (touchmove)="moved($event)"
        width="300"
        height="300"></canvas>
      </div>
      <div class="mt-5">
      <button class="btn btn-outline-danger my-2 me-2" (click)="clearPad($event)">Clear</button>
      <button class="btn btn-outline-success" (click)="submit()">
        <small class="loadingspinner d-inline-block ms-2" *ngIf="loader"></small>
        Submit Contract</button>
    </div>
    </form>
    </div>
  </div>