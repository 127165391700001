import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class JobCreateService {
    constructor(private readonly httpClient: HttpClient) {}

    //job category list
    getJobCategoryList(){
        return this.httpClient.get(`${environment.baseUrl}/candidate-job-details-management/categories-with-job-count`)
    }

    //create job
    createJob(payload: any) {
        return this.httpClient.post(
            `${environment.baseUrl}/candidate-job-details-management/add-job-info`,
            payload
        );
    }

  
    //filter job listing
    getJobListing(
        currentPage: number,
        totalItemPerPage: number,
        jobCategories: any[] = [],
        jobTitle: string = "",
        jobLocation: string = "",
        jobExperience: string = "",
        salaryRange: string = "",
        jobType: string = "",
        jobSearch: string = ""
    ) {
        // Convert jobCategories array to a JSON string and encode it
        const encodedCategories = encodeURIComponent(JSON.stringify(jobCategories));
        // Encode salaryRange to ensure it is properly formatted in the URL
        const encodedSalaryRange = encodeURIComponent(salaryRange);
        // Build the URL with all parameters encoded
        const url = `${environment.baseUrl}/candidate-job-details-management/get-all-job-info/${currentPage}/${totalItemPerPage}/?jobCategories=${encodedCategories}&jobTitle=${encodeURIComponent(jobTitle)}&jobLocation=${encodeURIComponent(jobLocation)}&jobExperience=${encodeURIComponent(jobExperience)}&salaryRange=${encodedSalaryRange}&jobType=${encodeURIComponent(jobType)}&jobSearch=${encodeURIComponent(jobSearch)}`;
        return this.httpClient.get(url);
    }

    //save jobs 
    saveJobs(candidateEmail:any , payLoad:any){
        return this.httpClient.post(
            `${environment.baseUrl}/candidate-wishlist-job-management/save-wishlist-job/${candidateEmail}`,
            payLoad
        );
    }

    //get candidate detail by email
    getCandidateDetailByEmail(email:any){
            return this.httpClient.patch(`${environment.baseUrl}/candidate/fetch-candidate-details/${email}` , {})
        }

    //get candidate contract detail
    getCandidateContractDetail(email:any){
        return this.httpClient.get(`${environment.baseUrl}/contract-management/fetch-contract-of-candidate/${email}` , {})
    }

    //send contract to candidate
    sendContract(payLoad:any){
        return this.httpClient.patch(
            `${environment.baseUrl}/contract-management/send-candidate-pdf-contract-to-client`,
            payLoad
        );
     }

    getSavedJobs(candidateEmail:any , page:number , limit:number){
        return this.httpClient.get(`${environment.baseUrl}/candidate-wishlist-job-management/fetch-unique-candidates-jobs/${candidateEmail}?page=${page}&limit=${limit}`)
    }


   //delete saved job
   deleteSavedJob(candidateEmail:any , jobId: any) {
    return this.httpClient.delete(
        `${environment.baseUrl}/candidate-wishlist-job-management/${candidateEmail}/${jobId}`,
    );
    }

    //create job
    deleteJob(Id: any) {
        return this.httpClient.delete(
            `${environment.baseUrl}/candidate-job-details-management/delete-specific-job-info/${Id}`,
        );
    }

    //update job
    updateJob(id: any, payload: any) {
            return this.httpClient.patch(
                `${environment.baseUrl}/candidate-job-details-management/update-job-info/${id}`,
                payload
            );
        }

   // get api of timsheet for admin
    CandidateTimsheetInfo(
    page: number,
    pageSize: number,
    candidateEmail: string = "",
    candidateDocId:string = "",
    startDate: string = "",
    endDate: string = ""
    ) {
    return this.httpClient.get(`${environment.baseUrl}/candidate-time-sheet-management/fetch-all-timesheets/${page}/${pageSize}?candidateEmail=${encodeURIComponent(candidateEmail)}&candidateDocId=${encodeURIComponent(candidateDocId)}&startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}`);
    }

   // get api of timsheet for admin by id
   CandidateTimsheetInfoById(
    candidateEmail: string = "",
    candidateDocId:string = "",
    startDate: string = "",
    endDate: string = ""
  ) {
    return this.httpClient.get(`${environment.baseUrl}/candidate-time-sheet-management/fetch-unique-timesheet-for-admin/${candidateEmail}/${candidateDocId}?startDate=${startDate}&endDate=${endDate}`);
  }
  
}
