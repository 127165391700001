export const environment = {
    production: false,
    title: ' Staging Environment',
    // baseUrl: "https://www.forestumbrella.co.uk/api/v1",
    baseUrl: "https://www.backend.magnusrecruitment.com.au/api/v1",
    // imageUrl: "https://www.forestumbrella.co.uk",
    imageUrl: "https://www.backend.magnusrecruitment.com.au",
    file_url:""
};



