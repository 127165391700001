import { Component, OnInit } from '@angular/core';
declare var Calendly: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentYear: number;

  constructor() { }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }


  openCalendly(): void {
    // Use Calendly's popup widget to open the scheduling interface
    Calendly.initPopupWidget({
        url: "https://calendly.com/accounts-magnusrecruitment/15min", // Replace with your scheduling link
    });
}

}
