<div class="navbar-area fixed-top ">
    <div class="row">
        <div>
            <div class="mobile-nav">
                <a routerLink="/" class="logo"><img src="../../../../assets/img/home-1/logo.png" alt="Logo" /></a>
            </div>
        </div>

        <!-- Navbar Toggler (for Mobile) -->
        <div class="col-12 text-end">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
        </div>

        <div class="col-12">
            <div class="main-nav">
                <div class="container-fluid d-flex justify-content-center">
                    <nav class="navbar navbar-expand-md navbar-light">
                        <a class="navbar-logo" routerLink="/"><img class="logo"
                                src="../../../../assets/img/home-1/logo.png" alt="Logo" /></a>

                        <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                            <ul class="navbar-nav ms-lg-5">
                                <li class="nav-item item">
                                    <a routerLink="/" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }">Home</a>
                                </li>

                                <li class="nav-item item">
                                    <a routerLink="/about" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }">About Us</a>
                                </li>

                                <li class="nav-item item" *ngIf="userInfo?.role == 'admin'">
                                    <a href="javascript:void(0)" class="nav-link dropdown-toggle">Listing<i
                                            class="icofont-simple-down"></i></a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item" *ngIf="userInfo?.role == 'admin'">
                                            <a routerLink="/admin/job-list" class="nav-link" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }">
                                                Job List
                                            </a>
                                        </li>
                                        <li class="nav-item" *ngIf="userInfo?.role == 'admin'">
                                            <a routerLink="/admin/job-categories" class="nav-link"
                                                routerLinkActive="active" [routerLinkActiveOptions]="{
                                                    exact: true
                                                }">
                                                Category List
                                            </a>
                                        </li>

                                        <!-- If the role does not exist or is undefined -->
                                        <li class="nav-item item" *ngIf="userInfo?.role !== 'admin'">
                                            <a routerLink="/job-list" class="nav-link" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }">
                                                Job List
                                            </a>
                                        </li>
                                    </ul>
                                </li>

                                <li *ngIf="userInfo?.role !== 'admin'" class="nav-item item">
                                    <a routerLink="/job-list" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }">Job List</a>
                                </li>
                      
                                <li *ngIf="
                                userInfo?.role !== 'admin' &&
                                userInfo?.role !== 'employee' && userInfo?.role !== 'candidate' "  class="nav-item item">
                                    <a routerLink="/candidate-list" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }">Candidate List</a>
                                </li>


                                <!-- <li class="nav-item item" *ngIf="userInfo?.role == 'candidate'">
                                    <a href="javascript:void(0)" class="nav-link dropdown-toggle">Candidates
                                        <i class="icofont-simple-down"></i></a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item" >
                                            <a routerLink="/candidate-list" class="nav-link"
                                                routerLinkActive="active" [routerLinkActiveOptions]="{
                                                    exact: true
                                                }">
                                                Candidate List
                                            </a>
                                        </li>
                                        <li class="nav-item" *ngIf="userInfo?.role == 'candidate'">
                                            <a routerLink="/candidate/aptitude-test" class="nav-link"
                                                routerLinkActive="active" [routerLinkActiveOptions]="{
                                                    exact: true
                                                }">
                                                Aptitude Test
                                            </a>

                                        </li> 
                                    </ul>
                                </li> -->

                                <li *ngIf="userInfo?.role == 'candidate'" class="nav-item item">
                                    <a routerLink="/candidate-list" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }">Candidate List</a>
                                </li>
                                
                                <li class="nav-item item" *ngIf="userInfo?.role == 'admin'">
                                    <a href="javascript:void(0)" class="nav-link dropdown-toggle">Candidates
                                        <i class="icofont-simple-down"></i></a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item" *ngIf="userInfo?.role == 'admin'">
                                            <a routerLink="/admin/candidate-list" class="nav-link"
                                                routerLinkActive="active" [routerLinkActiveOptions]="{
                                                    exact: true
                                                }">
                                                Active Candidate List
                                            </a>
                                        </li>
                                        <li class="nav-item" *ngIf="userInfo?.role == 'admin'">
                                            <a routerLink="/admin/candidate-applied-jobs" class="nav-link"
                                                routerLinkActive="active" [routerLinkActiveOptions]="{
                                                    exact: true
                                                }">
                                                Candidate applied jobs
                                            </a>

                                        </li>

                                        <!-- <li
                                        class="nav-item"
                                        *ngIf="userInfo?.role == 'admin'"
                                    >
                                        <a
                                            routerLink="/admin/candidate-list"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                        >
                                         Candidate Educational Certificate
                                        </a>
                                        </li> -->

                                        <li class="nav-item" *ngIf="userInfo?.role == 'admin'">
                                            <a routerLink="/admin/requested-candidate-list" class="nav-link"
                                                routerLinkActive="active" [routerLinkActiveOptions]="{
                                        exact: true
                                    }">
                                                Requested Candidate's List
                                            </a>
                                        </li>

                                        <li class="nav-item" *ngIf="userInfo?.role == 'admin'">
                                            <a routerLink="/admin/candidate-industry-verification" class="nav-link"
                                                routerLinkActive="active" [routerLinkActiveOptions]="{
                                        exact: true
                                    }">
                                             Industry Currency 
                                            </a>
                                        </li>

                                        <li class="nav-item" *ngIf="userInfo?.role == 'admin'">
                                            <a routerLink="/admin/candidate-reference-check" class="nav-link"
                                                routerLinkActive="active" [routerLinkActiveOptions]="{
                                        exact: true
                                    }">
                                             Reference Check 
                                            </a>
                                        </li>

                                        <!--  -->
                                        <li class="nav-item" *ngIf="userInfo?.role == 'admin'">
                                            <a routerLink="/admin/candidate-timesheet" class="nav-link"
                                                routerLinkActive="active" [routerLinkActiveOptions]="{
                                        exact: true
                                    }">
                                             Timesheet Detais
                                            </a>
                                        </li>
                                    </ul>
                                </li>



                                <!-- employer -->
                                <li class="nav-item item" *ngIf="userInfo?.role == 'employee'">
                                    <a href="javascript:void(0)" class="nav-link dropdown-toggle">Candidates
                                        <i class="icofont-simple-down"></i></a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item" *ngIf="userInfo?.role == 'employee'">
                                            <a routerLink="/employer/candidate-list" class="nav-link"
                                                routerLinkActive="active" [routerLinkActiveOptions]="{
                                                exact: true
                                            }">
                                                Candidate List
                                            </a>
                                        </li>
                                        <li class="nav-item" *ngIf="userInfo?.role == 'employee'">
                                            <a routerLink="/employer/assigned-candidates-list" class="nav-link"
                                                routerLinkActive="active" [routerLinkActiveOptions]="{
                                                exact: true
                                            }">
                                                Assigned Candidate's
                                            </a>
                                        </li>
                                    </ul>
                                </li>

                                <li class="nav-item item" *ngIf="userInfo?.role == 'admin'">
                                    <a href="javascript:void(0)" class="nav-link dropdown-toggle">Employer
                                        <i class="icofont-simple-down"></i></a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item" *ngIf="userInfo?.role == 'admin'">
                                            <a routerLink="/admin/employer-list" class="nav-link"
                                                routerLinkActive="active" [routerLinkActiveOptions]="{
                                                    exact: true
                                                }">
                                                Employer List
                                            </a>
                                        </li>

                                        <li class="nav-item" *ngIf="userInfo?.role == 'admin'">
                                            <a routerLink="/admin/looking-for-request" class="nav-link"
                                                routerLinkActive="active" [routerLinkActiveOptions]="{
                                                    exact: true
                                                }">
                                                Looking For Request
                                            </a>
                                        </li>


                                        <li class="nav-item" *ngIf="userInfo?.role == 'admin'">
                                            <a routerLink="/admin/all-assigned-candidate" class="nav-link"
                                                routerLinkActive="active" [routerLinkActiveOptions]="{
                                                    exact: true
                                                }">
                                                Assigned Candidate List
                                            </a>
                                        </li>

                                        <li class="nav-item" *ngIf="userInfo?.role == 'admin'">
                                            <a routerLink="/admin/stats-and-charts" class="nav-link"
                                                routerLinkActive="active" [routerLinkActiveOptions]="{
                                                    exact: true
                                                }">
                                                charts
                                            </a>
                                        </li>

                                        <!-- If the role does not exist or is undefined -->
                                        <li class="nav-item item" *ngIf="userInfo?.role == 'employee'">
                                            <a routerLink="/employer/candidate-list" class="nav-link"
                                                routerLinkActive="active" [routerLinkActiveOptions]="{
                                                    exact: true
                                                }">
                                                Candidate List
                                            </a>
                                        </li>
                                    </ul>
                                </li>

                                <li class="nav-item item">
                                    <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{
                                            exact: true
                                        }" routerLink="/candidate/job-status"
                                        *ngIf="userInfo?.role == 'candidate'">Applied Jobs</a>
                                </li>
                                <li class="nav-item item">
                                    <a routerLink="/blog" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }">Blog</a>
                                </li>
                                <li class="nav-item item">
                                    <a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }">Contact Us</a>
                                </li>

                                <li class="nav-item item d-block d-lg-none" *ngIf="user">
                                    <a class="rounded-circle" role="button" id="dropdownUser" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <div class="avatar avatar-md avatar-indicators avatar-online">
                                            <ng-container *ngIf="
                                                    userInfo
                                                        ?.candidateImageDetails
                                                        ?.imageUrl &&
                                                        userInfo?.candidateImageDetails.imageUrl.trim() !==
                                                            '';
                                                    else avatarImage
                                                ">
                                                <img [src]="
                                                        userInfo
                                                            ?.candidateImageDetails
                                                            ?.imageUrl
                                                    " class="rounded-circle profile-image" alt="Profile" />
                                            </ng-container>
                                            <ng-template #avatarImage>
                                                <img src="../../../assets/dummy/dummy-candidate.png"
                                                    class="rounded-circle profile-image" alt="Profile" />
                                            </ng-template>
                                        </div>
                                        {{ userInfo?.firstName }}
                                        {{ userInfo?.lastName }}
                                    </a>

                                    <ul class="dropdown-menu pb-2" aria-labelledby="dropdownUser">


                                        <li class="nav-item" *ngIf="
                                                    userInfo?.role ==
                                                    'candidate' 
                                                ">
                                            <a class="nav-link" routerLink="/candidate/candidate-profile">
                                                <span class="mr-1">
                                                    <!-- <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="12"
                                                            height="12"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            class="feather feather-user"
                                                        >
                                                            <path
                                                                d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"
                                                            ></path>
                                                            <circle
                                                                cx="12"
                                                                cy="7"
                                                                r="4"
                                                            ></circle>
                                                        </svg> -->
                                                </span>
                                                Profile
                                            </a>
                                        </li>

                                        <li class="nav-item" *ngIf="
                                                    userInfo?.role == 'employee'
                                                ">
                                            <a class="nav-link" routerLink="/employer/employer-profile">
                                                <span class="mr-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-user">
                                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                        <circle cx="12" cy="7" r="4"></circle>
                                                    </svg>
                                                </span>
                                                Profile
                                            </a>
                                        </li>



                                        <li class="nav-item" *ngIf="
                                                    userInfo?.role == 'admin'
                                                ">
                                            <a class="nav-link" routerLink="/admin/job-create">
                                                <span class="mr-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-user">
                                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                        <circle cx="12" cy="7" r="4"></circle>
                                                    </svg>
                                                </span>
                                                Profile
                                            </a>
                                        </li>
                                        <!-- </ul> -->
                                        <div class="dropdown-divider"></div>
                                        <!-- <ul class="list-unstyled"> -->
                                        <li class="nav-item">
                                            <a class="nav-link pointer logout-btn" href="javascript:void(0)"
                                                onclick="NavbarComponentInstance.handleLogout()">
                                                <span class="mr-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-power">
                                                        <path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
                                                        <line x1="12" y1="2" x2="12" y2="12"></line>
                                                    </svg>
                                                </span>
                                                Log
                                                Out
                                            </a>
                                        </li>
                                        <!-- </ul> -->
                                    </ul>
                                </li>




                                <div class="d-flex flex-column flex-lg-row login-signup">
                                    <div>
                                        <div class="calendly-logo calendly-logo-employer"
                                            [class.disabled]="userInfo?.role === 'employee'" (click)="openCalendly()">
                                            <img src="../../../../assets/img/home-1/calendly.png"
                                                class="appointment rounded-circle" />
                                            <span class="booking">Book an appointment</span>
                                        </div>
                                    </div>

                                    <!-- <div class="common-btn"> -->

                                    <ng-container *ngIf="!user">
                                        <div class="loginBtns">
                                            <div class="action-btns d-flex">
                                                <div class="common-btn">
                                                    <a class="login-btn" routerLink="/management-module/login"><i
                                                            class="icofont-plus-square"></i>
                                                        Login</a>
                                                    <a class="login-btn d-block d-lg-none"
                                                        routerLink="/management-module/candidate-sign-up"
                                                        routerLinkActive="active" [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"><i class="icofont-user-alt-4"></i>
                                                        Sign Up</a>
                                                </div>
                                                <!-- signup for large screen -->
                                                <div class="common-btn d-none d-lg-block">
                                                </div>
                                                <!-- signup for large screen -->
                                                <div class="common-btn">
                                                    <a class="sign-up-btn">
                                                        <ul class="navbar-nav">
                                                            <li class="nav-item" style="
                                                                border: 1px
                                                                    solid
                                                                    rgb(
                                                                        56,
                                                                        167,
                                                                        69
                                                                    );
                                                                background: linear-gradient(
                                                                    90deg,
                                                                    rgb(
                                                                            56,
                                                                            167,
                                                                            69
                                                                        )
                                                                        0%,
                                                                    rgb(
                                                                            76,
                                                                            206,
                                                                            91
                                                                        )
                                                                        63%
                                                                );
                                                            ">
                                                                <a href="javascript:void(0)" style="
                                                                    text-decoration: none;
                                                                    color: inherit;
                                                                ">
                                                                    <i class="icofont-user-alt-4"></i>Sign Up
                                                                    <i class="icofont-simple-down"></i></a>

                                                                <ul class="dropdown-menu">
                                                                    <li>
                                                                        <a routerLink="/management-module/employer-sign-up"
                                                                            routerLinkActive="active"
                                                                            [routerLinkActiveOptions]="{
                                                                            exact: true
                                                                        }" style="
                                                                            width: 240px;
                                                                        ">Sign up
                                                                            as
                                                                            Employer</a>
                                                                    </li>

                                                                    <li>
                                                                        <a routerLink="/management-module/candidate-sign-up"
                                                                            routerLinkActive="active"
                                                                            [routerLinkActiveOptions]="{
                                                                            exact: true
                                                                        }" style="
                                                                            width: 240px;
                                                                        ">Sign up
                                                                            as
                                                                            Candidate</a>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </a>
                                                </div>
                                                <!-- signup for large screen -->


                                            </div>
                                        </div>
                                    </ng-container>


                                    <div class="profile-container">
                                        <div class="container profile-logo d-none d-lg-block" *ngIf="user">
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12 col-12">
                                                    <ul class="list-unstyled">
                                                        <li class="dropdown">
                                                            <a class="rounded-circle" role="button" id="dropdownUser"
                                                                data-toggle="dropdown" aria-haspopup="true"
                                                                aria-expanded="false">
                                                                <div
                                                                    class="avatar avatar-md avatar-indicators avatar-online">
                                                                    
                                                                        <div class="initials-circle">
                                                                            {{ getInitials(userInfo?.firstName, userInfo?.lastName) }}
                                                                          </div>
                                                                    
                                                                    <!-- <ng-container *ngIf="
                                                                        userInfo
                                                                            ?.candidateImageDetails
                                                                            ?.imageUrl &&
                                                                            userInfo?.candidateImageDetails.imageUrl.trim() !==
                                                                                '';
                                                                        else avatarImage
                                                                    ">
                                                                        <img [src]="
                                                                            userInfo
                                                                                ?.candidateImageDetails
                                                                                ?.imageUrl
                                                                        " class="rounded-circle" alt="Profile" />
                                                                    </ng-container>
                                                                    <ng-template #avatarImage>
                                                                        <img src="../../../assets/dummy/dummy-candidate.png"
                                                                            class="rounded-circle" alt="Profile" />
                                                                    </ng-template> -->
                                                                </div>
                                                            </a>
                                                            <div class="dropdown-menu pb-2"
                                                                aria-labelledby="dropdownUser">
                                                                <!-- <div class="dropdown-item">
                                                                    <div class="d-flex py-2">
                                                                        <div
                                                                            class="avatar avatar-md avatar-indicators avatar-online">
                                                                            <ng-container *ngIf="
                                                                                userInfo
                                                                                    ?.candidateImageDetails
                                                                                    ?.imageUrl &&
                                                                                    userInfo?.candidateImageDetails.imageUrl.trim() !==
                                                                                        '';
                                                                                else avatarImage
                                                                            ">
                                                                                <img [src]="
                                                                                    userInfo
                                                                                        ?.candidateImageDetails
                                                                                        ?.imageUrl
                                                                                " class="rounded-circle "
                                                                                    alt="Profile" />
                                                                            </ng-container>
                                                                            <ng-template #avatarImage>
                                                                                <img src="../../../assets/dummy/dummy-candidate.png"
                                                                                    class="rounded-circle"
                                                                                    alt="Profile" />
                                                                            </ng-template>
                                                                        </div>
                                                                    
                                                                               <div class="ml-3 lh-1">
                                                                                <h5 class="mb-0">
                                                                                  {{ userInfo?.firstName }} {{ userInfo?.lastName }}
                                                                                </h5>
                                                                              </div> 
                                                                             <h5 class="mb-0">
                                                                                {{
                                                                                userInfo?.firstName
                                                                                }}
                                                                                {{
                                                                                userInfo?.lastName
                                                                                }} 
                                                                            </h5>
                                                                            <p class="mb-0">
                                                                                {{
                                                                                userInfo?.email
                                                                                }}
                                                                            </p>
                                                                    </div>
                                                                </div> -->
                                                                <!-- <div class="dropdown-divider"></div> -->
                                                                <ul class="list-unstyled mt-3">
                                                                    <li *ngIf="
                                                                        userInfo?.role ==
                                                                        'candidate'
                                                                    ">
                                                                        <a class="dropdown-item"
                                                                            routerLink="/candidate/candidate-profile">
                                                                            <span class="mr-1">
                                                                                <i class="fas fa-user"
                                                                                    style="font-size: 15px;"></i>
                                                                            </span>
                                                                            Profile
                                                                        </a>
                                                                    </li>

                                                                    <li *ngIf="
                                                                    userInfo?.role ==
                                                                    'candidate'
                                                                ">
                                                                        <a class="dropdown-item"
                                                                            routerLink="/candidate/reset-password">
                                                                            <span class="mr-1">
                                                                                <i class="fa fa-lock"></i>
                                                                            </span>
                                                                            Reset Password
                                                                        </a>
                                                                    </li>

                                                                    <li *ngIf="
                                                                        userInfo?.role ==
                                                                        'employee'
                                                                    ">
                                                                        <a class="dropdown-item"
                                                                            routerLink="/employer/employer-profile">
                                                                            <span class="mr-1">
                                                                                <i class="fas fa-user"
                                                                                    style="font-size: 15px;"></i>
                                                                            </span>
                                                                            Profile
                                                                        </a>
                                                                    </li>

                                                                    <li *ngIf="
                                                                    userInfo?.role ==
                                                                    'employee'
                                                                ">
                                                                        <a class="dropdown-item"
                                                                            routerLink="/employer/candidate-list">
                                                                            <span class="mr-1">
                                                                                <i class="fa fa-users"></i>

                                                                            </span>
                                                                            Candidate List
                                                                        </a>
                                                                    </li>

                                                                    <li *ngIf="
                                                                    userInfo?.role ==
                                                                    'employee'
                                                                ">
                                                                        <a class="dropdown-item"
                                                                            routerLink="/employer/assigned-candidates-list">
                                                                            <span class="mr-1">
                                                                                <i class="fa fa-tag"></i>
                                                                            </span>
                                                                            Assigned Candidates
                                                                        </a>
                                                                    </li>

                                                                    <li *ngIf="
                                                                userInfo?.role ==
                                                                'employee'
                                                            ">
                                                                        <a class="dropdown-item"
                                                                            routerLink="/employer/reset-password">
                                                                            <span class="mr-1">
                                                                                <i class="fa fa-lock"></i>
                                                                            </span>
                                                                            Reset Password
                                                                        </a>
                                                                    </li>
                                                                    <li *ngIf="
                                                                        userInfo?.role ==
                                                                        'admin'
                                                                    ">
                                                                        <a class="dropdown-item"
                                                                            routerLink="/admin/job-create">
                                                                            <span class="mr-1">
                                                                                <i class="fas fa-user"
                                                                                    style="font-size: 15px;"></i>

                                                                            </span>
                                                                            <span *ngIf="
                                                                         userInfo?.role ==
                                                                         'admin' ; else showProfile">
                                                                                Post a Job
                                                                            </span>
                                                                            <ng-template #showProfile>
                                                                                <span>Profile</span>
                                                                            </ng-template>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                                <div class="dropdown-divider"></div>
                                                                <ul class="list-unstyled">
                                                                    <li class="nav-item ms-2">
                                                                        <a class="nav-link pointer" (click)="
                                                                            handleLogout()
                                                                        ">
                                                                            <span class="mr-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    width="14" height="14"
                                                                                    viewBox="0 0 24 24" fill="none"
                                                                                    stroke="currentColor"
                                                                                    stroke-width="2"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    class="feather feather-power">
                                                                                    <path
                                                                                        d="M18.36 6.64a9 9 0 1 1-12.73 0">
                                                                                    </path>
                                                                                    <line x1="12" y1="2" x2="12"
                                                                                        y2="12"></line>
                                                                                </svg>
                                                                            </span>
                                                                            Log Out
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- </div> -->
                                </div>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>